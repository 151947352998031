#root {
    height: 100%;
    min-height: 100%;
}

body {
    /* background-image: radial-gradient(#ffffff, rgb(238, 239, 240)); */
    background-color:  rgb(238, 239, 240);
    min-height: 100%;
    height: 100%;
}

html {
    overflow: auto;
    min-height: 100%;
    height: 100%;
}

.halfWidth {
    min-width: 50%
}

.width70 {
    width: 70%
}

.strike {
    text-decoration: line-through;
}

.grocery-card {
    width: 70%;
}

.gr-card {
    max-width: 800px;
    min-width: 50%;
}

.gr-card-title {
    /* font-size:3vw; */
    font-size:clamp(1.4rem, 3vw, 1.75rem);
}

.gr-card-subtitle {
    font-size:clamp(0.8rem, 1.6vw, 1rem);
    overflow: hidden;
    text-overflow: ellipsis;
}


.thumbnail {
    max-width: 130px;
    width: 100%;
    height: auto;

}


.editorPanel {
    border: '1px solid black';
    padding: '5px';
    borderRadius: '2px';
    height: '500px';
    width: '100%';
}